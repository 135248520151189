import { ModalServiceInterface } from './modal-service.interface';

export class ModalService implements ModalServiceInterface {
    open(modalButtonId: string): void {
        const instance = this.getModalButtonInstance(modalButtonId);
        instance?.click();
    }
    close(modalButtonId: string, modalId: string): void {
        const instance = this.getModalButtonInstance(modalButtonId);
        if (instance) instance?.click();
        const hsOverlayInstance = window.HSOverlay?.getInstance(
            `[data-hs-overlay="#hs-${modalId}"]`,
            true
        );
        if (hsOverlayInstance)
            window.HSOverlay?.close(hsOverlayInstance.element.el);
    }
    private getModalButtonInstance(modalButtonId: string) {
        return document.getElementById(modalButtonId);
    }
}
