import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';
import { ModalService } from './modal.service';
import { usePrelineHs } from '~/hooks/use-preline-hs.hook';

type ModalContextType = {
    open: (component: React.ReactNode) => void;
    close: () => void;
};

export enum ModalId {
    ROOT = 'root-modal',
    SUBSCRIPTIONS_PAGE = 'subscriptions-page',
    SUBSCRIPTIONS_TABLE = 'subscriptions-table',
}

const ModalContext = createContext<ModalContextType | null>(null);

const ModalProvider = ({
    children,
    modalId,
}: PropsWithChildren<{ modalId: ModalId | string }>) => {
    const [content, setContent] = useState<React.ReactNode>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const modalButtonId = `modal-context-button-${modalId}`;
    usePrelineHs();
    const service = new ModalService();

    useEffect(() => {
        // prevent layout shift when scrollbar is removed on modal
        const body = document.body;
        if (isModalOpen) {
            // Calculate scrollbar width
            const scrollbarWidth =
                window.innerWidth - document.documentElement.clientWidth;
            // Add padding to prevent shift
            body.style.paddingRight = `${scrollbarWidth}px`;
            body.style.overflow = 'hidden';
        } else {
            // Remove padding and restore scroll
            body.style.paddingRight = '';
            body.style.overflow = '';
        }
        return () => {
            // Clean up styles
            body.style.paddingRight = '';
            body.style.overflow = '';
        };
    }, [isModalOpen]);

    const open = (component: React.ReactNode) => {
        setContent(undefined);
        service.close(modalButtonId, modalId);
        setContent(component);
        service.open(modalButtonId);
        setIsModalOpen(true);
    };

    const close = () => {
        setContent(undefined);
        service.close(modalButtonId, modalId);
        setIsModalOpen(false);
    };

    return (
        <ModalContext.Provider value={{ open, close }}>
            <button
                type='button'
                className='hidden py-3 px-4 items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none'
                aria-haspopup='dialog'
                aria-expanded='false'
                aria-controls={`hs-${modalId}`}
                data-hs-overlay={`#hs-${modalId}`}
                id={modalButtonId}
            >
                Open modal
            </button>
            <div
                id={`hs-${modalId}`}
                data-hs-overlay-keyboard='false'
                className='hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none [--overlay-backdrop:static]'
                role='dialog'
                tabIndex={-1}
            >
                <div className='hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center'>
                    <div className='w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70'>
                        {content}
                    </div>
                </div>
            </div>
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => {
    const ctx = useContext(ModalContext);
    if (!ctx) {
        // throwing here is a nice touch because this is a developer error
        // and if this happened during production, something must have really broken
        throw new Error(
            'useToastContext can only be used in a ModalContext-Provider tree'
        );
    }
    return ctx;
};

export default ModalProvider;
